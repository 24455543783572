import React from 'react'
import { components } from 'react-select'

export default function ImageSingleValue(props) {
  const label = props.data.thumbnail ? (
    <div className="flex flex-v">
      <img src={props.data.thumbnail} className="select-thumbnail" />
      {props.data.label}
    </div>
  ) : (
    props.data.label
  )
  return <components.SingleValue {...props}>{label}</components.SingleValue>
}
