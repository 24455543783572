import React from 'react'
import AsyncSelect from 'react-select/async'

import Option from './ImageOption'
import SingleValue from './ImageSingleValue'

class AdminSearch extends React.Component {
  state = {
    value: this.props.value || null,
  }

  render() {
    return (
      <AsyncSelect
        cacheOptions
        components={{ Option, SingleValue }}
        defaultOptions
        isMulti={this.props.isMulti || false}
        loadOptions={this.loadOptions}
        name={this.props.name}
        onChange={this.onChange}
        value={this.state.value}
      />
    )
  }

  loadOptions = q => {
    const { type } = this.props
    const url = `/admin_search?type=${type}&q=${q ? encodeURIComponent(q) : ''}`
    return fetch(url).then(res => res.json())
  }

  onChange = value => {
    this.setState({ value })
  }
}

export default AdminSearch
