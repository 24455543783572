// Rails internals
import RailsUJS from '@rails/ujs'
RailsUJS.start()

// Turbolinks
// Extra init from https://github.com/reactjs/react-rails#event-handling
import Turbolinks from 'turbolinks'
// Order is particular. First start Turbolinks:
Turbolinks.start()
// Add Turbolinks to the global namespace:
window.Turbolinks = Turbolinks
// Remove previous event handlers and add new ones:
ReactRailsUJS.detectEvents()
// (Optional) Clean up global namespace:
delete window.Turbolinks

// React Rails
import ReactRailsUJS from 'react_ujs'
const componentRequireContext = require.context('components', true)
ReactRailsUJS.useContext(componentRequireContext)

// Font Awesome
import {} from '@fortawesome/fontawesome-free/js/all'

// App styles
import '../styles/application'
