import React from 'react'
import { components } from 'react-select'

export default function ImageOption(props) {
  const label = props.data.thumbnail ? (
    <div className="flex flex-v">
      <img src={props.data.thumbnail} className="select-thumbnail" />
      {props.label}
    </div>
  ) : (
    props.label
  )
  return <components.Option {...props}>{label}</components.Option>
}
