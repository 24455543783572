import React from 'react'
import ReactMapboxGl, { Layer, Popup, Feature } from 'react-mapbox-gl'
import { LngLatBounds } from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

const MAPBOX_TOKEN =
  'pk.eyJ1IjoidXJiYW5hcmNoaXZlIiwiYSI6ImNqZHc2cGlrNTAxYTAzM28xOWpsdzVsNmkifQ.9cK3OpUjVGHRmESyS_AL2A'
const UA_STYLE = 'mapbox://styles/urbanarchive/cjgmit1lm000f2rqtaxi3ijqy'

const MAP_CENTER = [-73.935242, 40.73061]
const CIRCLE_PAINT = {
  'circle-color': '#952cf0',
  'circle-opacity': 0.75,
  'circle-stroke-width': 1,
  'circle-stroke-color': 'white',
  'circle-stroke-opacity': 0.25,
}

const Map = ReactMapboxGl({ accessToken: MAPBOX_TOKEN })

class BoxMap extends React.Component {
  state = { activeID: null }
  byID = {}
  maxValue = 0
  bounds = null

  constructor(props) {
    super(props)
    const bounds = new LngLatBounds()
    props.points.forEach((p) => {
      this.byID[p.id] = p
      if (p.value && p.value > this.maxValue) this.maxValue = p.value
      bounds.extend(p.lonlat)
    })
    // React Mapbox only works with the array form
    this.bounds = bounds.toArray()
  }

  render() {
    const { height, marker, points, width } = this.props
    const { activeID } = this.state
    const popup = activeID ? this.byID[activeID] : null
    const paint = { ...CIRCLE_PAINT }
    const extraScale = this.maxValue > 1 ? [this.maxValue, '#952cf0'] : []
    paint['circle-color'] = ['interpolate', ['linear'], ['get', 'value'], 0, 'red', 1, 'green', ...extraScale]
    return (
      <Map
        center={MAP_CENTER}
        containerStyle={{ height, marginBottom: '20px', width }}
        fitBounds={this.bounds}
        fitBoundsOptions={{ padding: 50 }}
        style={UA_STYLE}
      >
        <Layer type="circle" id="items" paint={paint}>
          {points.map((point) => (
            <Feature
              coordinates={point.lonlat}
              key={point.id}
              onClick={this.click}
              onMouseEnter={this.mouseEnter}
              onMouseLeave={this.mouseLeave}
              properties={{ kioskID: point.id, value: point.value }}
            />
          ))}
        </Layer>
        {marker && marker.lonlat ? (
          <Layer
            type="symbol"
            id="marker"
            layout={{
              'icon-anchor': 'bottom',
              'icon-image': 'marker-15',
              'icon-size': 2,
            }}
          >
            <Feature coordinates={marker.lonlat} />
          </Layer>
        ) : null}
        {popup && popup.lonlat ? (
          <Popup coordinates={popup.lonlat}>
            <strong>{popup.title}</strong>
            {popup.text && <div>{popup.text}</div>}
          </Popup>
        ) : null}
      </Map>
    )
  }

  click = ({ features, map }) => {
    const point = this.byID[features[0].properties.kioskID]
    if (!point) return
    if (features.length > 1) {
      // Zoom in to disambiguate click
      map.easeTo({ center: point.lonlat, zoom: map.getZoom() + 1 })
    } else if (features.length === 1) {
      const { linkPath } = this.props
      if (!linkPath) {
        return
      }

      // Link to the clicked point
      window.location.assign(this.props.linkPath + point.id)
    }
  }

  mouseEnter = ({ feature, map }) => {
    map.getCanvas().style.cursor = 'pointer'
    this.setState({ activeID: feature.properties.kioskID })
  }

  mouseLeave = ({ map }) => {
    map.getCanvas().style.cursor = ''
    this.setState({ activeID: null })
  }
}

export default BoxMap
